.policy-page {
    min-height: 100vh;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .policy-container {
    max-width: 800px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .policy-header {
    background-color: #EAB026;
    padding: 30px 20px;
    text-align: center;
    color: #fff;
  }
  
  .policy-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .policy-subtitle {
    font-size: 16px;
    opacity: 0.9;
  }
  
  .policy-content {
    padding: 30px;
  }
  
  .policy-section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 22px;
    font-weight: bold;
    color: #EAB026;
    border-bottom: 2px solid #EAB026;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  
  .policy-text {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  .policy-list {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .policy-list-item {
    margin-bottom: 10px;
    position: relative;
  }
  
  .policy-list-item::before {
    content: "•";
    color: #EAB026;
    font-weight: bold;
    position: absolute;
    left: -15px;
  }
  
  .policy-link {
    color: #EAB026;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .policy-link:hover {
    color: #C4901E;
  }
  
  .policy-divider {
    margin: 30px 0;
    border: none;
    border-top: 1px solid #e0e0e0;
  }
  
  @media (max-width: 600px) {
    .policy-page {
      padding: 20px 10px;
    }
  
    .policy-content {
      padding: 20px;
    }
  
    .policy-title {
      font-size: 24px;
    }
  
    .section-title {
      font-size: 20px;
    }
  }
  