.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    margin: 0 16px;
    animation: fadeInUp 0.5s ease-out forwards;
  }
  
  .modal-title {
    font-size: 32px;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 16px;
  }
  
  .modal-description {
    font-size: 18px;
    color: #666;
    margin-bottom: 32px;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .modal-button {
    width: 100%;
    padding: 16px;
    font-size: 18px;
    font-weight: 600;
    background-color: #eab026;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .modal-button:hover {
    background-color: #d99e22;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  