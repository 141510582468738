.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  font-family: "Roboto", sans-serif;
  transition: all 0.3s ease;
}

.header.scrolled {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-container {
  flex-shrink: 0;
}

.logo {
  width: 120px;
  height: 40px;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.logo:hover {
  transform: scale(1.02);
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
  padding: 8px;
  border-radius: 8px;
}

.menu-toggle:hover {
  color: #eab026;
  background-color: rgba(234, 176, 38, 0.1);
}

.menu-toggle:focus-visible {
  outline: 2px solid #eab026;
  outline-offset: 2px;
}

.desktop-nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-link {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  padding: 8px 12px;
  border-radius: 8px;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 50%;
  background-color: #eab026;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-link:hover {
  color: #eab026;
  background-color: rgba(234, 176, 38, 0.1);
}

.nav-link:hover::after {
  width: calc(100% - 24px);
}

.nav-link:focus-visible {
  outline: 2px solid #eab026;
  outline-offset: 2px;
}

.store-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
}

.store-link {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
}

.store-badge {
  height: 40px;
  width: auto;
  transition: all 0.2s ease;
}

.store-link:hover {
  transform: translateY(-2px);
}

.store-link:focus-visible {
  outline: 2px solid #eab026;
  outline-offset: 4px;
  border-radius: 8px;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(248, 248, 248, 0.98);
  backdrop-filter: blur(10px);
}

.mobile-nav.open {
  max-height: 400px;
  padding: 20px 0;
}

.mobile-nav .nav-link {
  padding: 12px 20px;
  width: 100%;
  text-align: center;
}

.mobile-nav .store-buttons {
  padding: 20px 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mobile-nav .store-link {
  padding: 8px 0;
  justify-content: center;
}

@media (max-width: 1024px) {
  .desktop-nav {
    display: none;
  }
}

@media (min-width: 1025px) {
  .menu-toggle {
    display: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .header,
  .logo,
  .nav-link,
  .store-link,
  .store-badge,
  .mobile-nav {
    transition: none;
  }
}

