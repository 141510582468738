@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 0.5s ease-out forwards;
}

.page-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  padding: 40px 20px;
}

.hero-section {
  max-width: 1200px;
  margin: 0 auto 40px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hero-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);
}

.hero-content {
  padding: 60px;
}

.hero-title {
  font-size: 42px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 24px;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 36px;
  max-width: 600px;
}

.store-buttons {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.store-link {
  transition: transform 0.2s ease;
}

.store-badge {
  height: 54px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.store-link:hover {
  transform: translateY(-2px);
}

.store-link:focus-visible {
  outline: 2px solid #eab026;
  outline-offset: 4px;
  border-radius: 8px;
}

.app-preview {
  background-color: #f0f4f8;
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.app-preview img:hover {
  transform: scale(1.02);
}

.contact-form {
  max-width: 550px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  padding: 40px;
  transition: transform 0.3s ease;
}

.contact-form:hover {
  transform: translateY(-5px);
}

.form-title {
  font-size: 32px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.form-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 32px;
}

.form-group {
  margin-bottom: 24px;
}

.form-label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #444;
  margin-bottom: 10px;
}

.form-input {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 2px solid #eef1f6;
  border-radius: 12px;
  background-color: #f8fafc;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.form-input:focus {
  border-color: #eab026;
  box-shadow: 0 0 0 3px rgba(234, 176, 38, 0.1);
  outline: none;
}

.form-input::placeholder {
  color: #94a3b8;
}

.form-button {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #eab026;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 8px;
}

.form-button:hover:not(:disabled) {
  background-color: #d99e22;
  transform: translateY(-2px);
}

.form-button:active:not(:disabled) {
  transform: translateY(0);
}

.form-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.form-button:focus-visible {
  outline: 2px solid #eab026;
  outline-offset: 4px;
}

.form-status {
  margin-bottom: 24px;
  margin-top: 0;
  padding: 16px;
  border-radius: 12px;
  font-size: 16px;
  animation: fadeInUp 0.3s ease-out forwards;
}

.form-status-success {
  background-color: #d1fae5;
  color: #065f46;
}

.form-status-error {
  background-color: #fee2e2;
  color: #991b1b;
}

@media (max-width: 900px) {
  .app-preview{
    padding: 15px 10px;
  }
}

@media (min-width: 830px) {
  .hero-section {
    display: flex;
  }

  .hero-content {
    flex: 1;
  }

  .app-preview {
    flex: 1;
  }
}

@media (max-width: 830px) {
  .page-container {
    padding: 20px 15px;
  }

  .hero-content,
  .contact-form {
    padding: 40px 30px;
  }

  .app-preview{
    padding: 30px 15px;
  }

  .hero-title {
    font-size: 32px;
  }

  .form-title {
    font-size: 28px;
  }
}

@media (max-width: 500px) {
  .page-container {
    padding: 20px 15px;
  }

  .hero-content,
  .contact-form {
    padding: 40px 30px;
  }

  .app-preview{
    padding: 15px 10px;
  }

  .hero-title {
    font-size: 32px;
  }

  .form-title {
    font-size: 28px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade-in-up,
  .hero-section,
  .store-link,
  .store-badge,
  .app-preview img,
  .contact-form,
  .form-input,
  .form-button {
    animation: none;
    transition: none;
  }
}


.input-error {
  border-color: #ff4136;
}

.error-message {
  color: #ff4136;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: block;
}

.form-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}